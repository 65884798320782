import { IMenuItem } from "@/ts"
// import { useRouter } from 'vue-router'
// import router from "@/router/index"
import {defineEmits} from "vue"

export default {
    changeTagger:(menuItem:IMenuItem):void => {
        if(menuItem.tagger) {
            menuItem.tagger = !menuItem.tagger
        }else {
            menuItem.tagger = false
        }
    },
    //路由跳转
    routeJump:(menuItem:IMenuItem, parentMenuItem:IMenuItem):void =>{
    //   // 加入keepalive缓存
    //   this.$store.commit('addKeepAliveCache', name)
      //访问wellcome 就代表home
    //   var tagName = menuItem.url === 'wellcome' ? 'home' : data.url
    //   var submenu = {
    //     path: tagName,
    //     name: tagName,
    //     title: data.name,
    //     parentId: this.$route.query.parentId
    //   }
    //   //修改选中菜单
    //   this.$store.commit('selectMenu', submenu)
        console.log('>>>>>')
        const emit=defineEmits(['open']);
        console.log(parentMenuItem)
        console.log(emit);
        // const router = useRouter()
        // router.push({
        //     path: menuItem.url
        // })
        emit('open', menuItem);
    }
}
